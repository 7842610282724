import actionTypes from "./actionTypes";
import {STATE_WAITING_FOR_APPROVAL} from "../../types/ProfilePictures";
import {CONNECTOR_OR, FILTER_TYPE_EQUAL, FILTER_TYPE_LIKE} from "../../services/PaginationFilterBuilder";

const initialState = {
    isLoading: true,
    isSubmitting: false,
    userVerifications: {
        data: [],
        meta: {},
    },
    picturesData: {},
    approvedPictures: [],
    deniedPictures: [],
    filterValue: '',
    idFilterValue: null,
    filterParameters: [{
        name: 's.name',
        value: STATE_WAITING_FOR_APPROVAL,
        type: FILTER_TYPE_EQUAL
    }],
    pageNumber: 1,
};

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case actionTypes.VERIFICATIONS_CLEANUP:
            return {
                ...initialState,
            };
        case actionTypes.SET_USER_VERIFICATIONS:
            return {
                ...state,
                userVerifications: payload,
            };
        case actionTypes.ADD_USER_VERIFICATIONS:
            return {
                ...state,
                userVerifications: {
                    data: [
                        ...state.userVerifications.data,
                        ...payload.data,
                    ].reduce((array, item) => { // Remove duplicates
                        if (!array.find(checkedItem => checkedItem.id === item.id)) {
                            array.push(item);
                        }

                        return array;
                    }, []),
                    meta: payload.meta,
                }
            };
        case actionTypes.ADD_APPROVED:
            return addApproved(state, payload);
        case actionTypes.ADD_DENIED:
            return addDenied(state, payload);
        case actionTypes.RESET_RESOLUTION:
            return resetResolution(state, payload);
        case actionTypes.RESET_ALL_RESOLUTIONS:
            return {
                ...state,
                approvedPictures: [],
                deniedPictures: [],
            };
        case actionTypes.SET_VERIFICATIONS_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        case actionTypes.SET_RESOLUTIONS_SUBMITTING:
            return {
                ...state,
                isSubmitting: payload,
            };
        case actionTypes.SET_FILTER_VALUE:
            return {
                ...state,
                filterValue: payload,
                filterParameters: [
                    ...initialState['filterParameters'],
                    {
                        name: 'u.firstName',
                        value: `%${payload}%`,
                        type: FILTER_TYPE_LIKE,
                        connector: CONNECTOR_OR,
                    },
                    {
                        name: 'u.lastName',
                        value: `%${payload}%`,
                        type: FILTER_TYPE_LIKE,
                    },
                ],
            }
        case actionTypes.SET_ID_FILTER_VALUE:
            const filterParameters = [...state['filterParameters'].filter((filterParam) => filterParam.name !== 'u.id')]
            if (payload !== null) {
                filterParameters.push({
                    name: 'u.id',
                    value: payload,
                    type: FILTER_TYPE_EQUAL,
                    connector: CONNECTOR_OR,
                })
            }

            return {
                ...state,
                idFilterValue: payload,
                filterParameters,
            }
        case actionTypes.RESET_FILTER_VALUES:
            return {
                ...state,
                filterValue: '',
                idFilterValue: null,
                filterParameters: [...initialState['filterParameters']],
            }
        case actionTypes.INCREASE_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: state.pageNumber + 1,
            };
        case actionTypes.SET_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: payload,
            };
        case actionTypes.ADD_PICTURE_PROFILE_IMAGE:
            return addPictureImages(state, payload, 'profilePicture');
        case actionTypes.ADD_PICTURE_CONFIRMATION_DOCUMENT:
            return addPictureImages(state, payload, 'confirmationDocument');
        default:
            return state;
    }
};

const addPictureImages = (state, payload, type) => {
    const picturesData = {...state.picturesData};

    if (picturesData[payload.pictureId] === undefined) {
        picturesData[payload.pictureId] = {
            profilePicture: null,
            confirmationDocument: null,
        }
    }

    picturesData[payload.pictureId][type] = payload.data;

    return {
        ...state,
        picturesData,
    };
};

const addApproved = (state, pictureId) => {
    const approvedPictures = [...state.approvedPictures];
    if (approvedPictures.includes(pictureId) === false) {
        approvedPictures.push(pictureId);
    }

    return {
        ...state,
        approvedPictures,
    };
};

const addDenied = (state, pictureId) => {
    const deniedPictures = [...state.deniedPictures];
    if (deniedPictures.includes(pictureId) === false) {
        deniedPictures.push(pictureId);
    }

    return {
        ...state,
        deniedPictures,
    };
};

const resetResolution = (state, pictureIdToReset) => {
    const filter = resolvedPictureId => resolvedPictureId !== pictureIdToReset;

    const approvedPictures = [...state.approvedPictures].filter(filter);
    const deniedPictures = [...state.deniedPictures].filter(filter);

    return {
        ...state,
        approvedPictures,
        deniedPictures,
    };
};

export default reducer;
