const namespaces = [
    'common',
    'routes',
    'translations',
    'profilePictureVerification',
    'customers',
    'dataGrid',
    'individualSalesService',
    'tenants',
];

export default namespaces;
