const actionTypes = {
    // Customer
    CUSTOMERS_CLEANUP: 'CUSTOMERS_CLEANUP',
    SET_CUSTOMER_LOADING: 'SET_CUSTOMER_LOADING',
    SET_CUSTOMER: 'SET_CUSTOMER',
    ADD_VALIDATION_ERROR: 'ADD_VALIDATION_ERROR',
    REMOVE_VALIDATION_ERRORS: 'REMOVE_VALIDATION_ERRORS',

    // Customer
    SET_PROFILE_PICTURE_LOADING: 'SET_PROFILE_PICTURE_LOADING',
    SET_PROFILE_PICTURE: 'SET_PROFILE_PICTURE',

    // Cards
    SET_CARDS_LOADING: 'SET_CARDS_LOADING',
    SET_CARDS: 'SET_CARDS',
    SET_ACTIVE_CARD_LOADING: 'SET_ACTIVE_CARD_LOADING',
    SET_ACTIVE_CARD: 'SET_ACTIVE_CARD',

    // Insurances
    SET_INSURANCES_LOADING: 'SET_INSURANCES_LOADING',
    SET_INSURANCES: 'SET_INSURANCES',
    SET_ACTIVE_INSURANCE_LOADING: 'SET_ACTIVE_INSURANCE_LOADING',
    SET_ACTIVE_INSURANCE: 'SET_ACTIVE_INSURANCE',

    // Agreements
    SET_AGREEMENTS_LOADING: 'SET_AGREEMENTS_LOADING',
    SET_AGREEMENTS: 'SET_AGREEMENTS',
};

export default actionTypes;
