import React, {useEffect} from 'react'
import './scss/style.scss';
import 'spinkit/spinkit.css';
import {useKeycloak} from "@react-keycloak/web";
import {CFade} from "@coreui/react";
import {useDispatch, useSelector} from 'react-redux'
import Page500 from "./containers/errorPages/page500";
import Page404 from "./containers/errorPages/page404";
import {useTranslation} from "react-i18next";
import FullScreenLoader from "./components/FullScreenLoader";
import {getTenantsWithState} from './store/tenants/selectors'
import {fetchTenantsWithEnabledPortal} from './services/Tenants'
import Page401 from './containers/errorPages/page401'

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

const Loader = () => (
    <CFade>
        <FullScreenLoader/>
    </CFade>
)

const App = () => {
    const page500 = useSelector(state => state.UI.page500)
    const page401 = useSelector(state => state.UI.page401)
    const page404 = useSelector(state => state.UI.page404)
    const tenants = useSelector(getTenantsWithState)

    const dispatch = useDispatch()
    const {keycloak, initialized} = useKeycloak()
    const {t} = useTranslation()

    useEffect(() => {
        // When user is authenticated but tenants are not already loaded
        if (initialized && keycloak.authenticated && !tenants.isLoaded) {
            dispatch(fetchTenantsWithEnabledPortal())
        }
    }, [initialized, keycloak.authenticated, tenants.isLoaded, dispatch])

    if (page500.show) {
        return (
            <CFade>
                <Page500/>
            </CFade>
        );
    }

    if (page401.show) {
        return (
            <CFade>
                <Page401/>
            </CFade>
        );
    }

    if (page404.show) {
        return (
            <CFade>
                <Page404/>
            </CFade>
        );
    }

    // When Keycloak is not initialized, show loader
    if (initialized === false) {
        return <Loader/>
    }

    // When User is not authenticated, initiate login
    if (keycloak.authenticated === false) {
        keycloak.login();

        return t('common:unauthenticated');
    }

    // SWhen list of Tenants is still loading, show loader
    if (tenants.isLoading) {
        return <Loader/>
    }

    return <TheLayout/>;
}

export default App;
