import tenantTypes from './actionTypes';
import { profileManagerClient, tenantsClient, issClient } from '../../utils/Http';

const initialState = {
    tenants: [],
    activeTenant: null,
    isLoading: true,
    isLoaded: false,
}

const setTenantHeaderToHttpClients = (tenantCode) => {
    profileManagerClient.defaults.headers.common['X-Tenant-Code'] = tenantCode;
    tenantsClient.defaults.headers.common['X-Tenant-Code'] = tenantCode;
    issClient.defaults.headers.common['X-Tenant-Code'] = tenantCode;
}

const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case tenantTypes.TENANTS_LOADING:
            return {
                ...state,
                isLoading: payload.isLoading,
            };
        case tenantTypes.TENANTS_FETCHED:
            setTenantHeaderToHttpClients(payload.tenants[0].code)

            return {
                ...state,
                tenants: payload.tenants,
                activeTenant: payload.tenants[0],
                isLoading: false,
                isLoaded: true,
            }
        case tenantTypes.TENANT_SELECTED:
            setTenantHeaderToHttpClients(payload.tenantCode)

            return {
                ...state,
                activeTenant: state.tenants.find((tenant) => tenant.code === payload.tenantCode) || null
            }
        default:
            return state;
    }
}

export default reducer;
