import UITypes from './actionTypes';

const initialState = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false,
    page500: {
        show: false,
        message: '',
    },
    page401: {
        show: false,
        message: '',
    },
    page404: {
        show: false,
        message: '',
    },
    scrollNavigation: [],
}

const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case UITypes.SHOW_SIDEBAR:
        case UITypes.SHOW_ASIDE:
        case UITypes.DARKMODE:
            return {...state, ...payload};
        case UITypes.SHOW_PAGE_500:
            const newStateWith500 = {...state, ...payload};
            if (newStateWith500['page500']['show'] === false) {
                newStateWith500['page500']['message'] = '';
            }

            return newStateWith500;
        case UITypes.SHOW_PAGE_401:
            const newStateWith401 = {...state, ...payload};
            if (newStateWith401['page401']['show'] === false) {
                newStateWith401['page401']['message'] = '';
            }

            return newStateWith401;
        case UITypes.SHOW_PAGE_404:
            const newStateWith404 = {...state, ...payload};
            if (newStateWith404['page404']['show'] === false) {
                newStateWith404['page404']['message'] = '';
            }

            return newStateWith404;
        case UITypes.ADD_SCROLL_NAVIGATION:
            let scrollNavigation = [...state.scrollNavigation];

            // If navigation with given ID already exists, replace (to be able to update content)
            if (scrollNavigation.includes(navigation => navigation.id === payload.id)) {
                scrollNavigation = scrollNavigation.filter(navigation => navigation.id !== payload.id);
            }
            scrollNavigation.push(payload);

            return {
                ...state,
                scrollNavigation,
            };
        case UITypes.CLEANUP_SCROLL_NAVIGATION:
            return {
                ...state,
                scrollNavigation: [],
            };
        default:
            return state;
    }
}

export default reducer;
