export const getTenantsWithState = (state) => ({
    tenants: state.Tenants.tenants,
    activeTenant: state.Tenants.activeTenant,
    isLoading: state.Tenants.isLoading,
    isLoaded: state.Tenants.isLoaded,
})

export const selectActiveTenant = (state) => ({...state.Tenants.activeTenant})

export const getTenants = (state) => ({
    activeTenant: state.Tenants.activeTenant,
    additionalTenants: state.Tenants.tenants.filter((tenant) => tenant.code !== state.Tenants.activeTenant.code),
})

export const getTenantsCurrencies = (state) => {
    // Convert currencies object into array
    const currencies = Object.keys(state.Tenants.activeTenant.currencies)
        .map((currencyCode) => ({
            currency_code: currencyCode,
            ...state.Tenants.activeTenant.currencies[currencyCode],
        }))

    return {
        defaultCurrency: currencies.find((currency) => currency.is_default) || currencies[0],
        alternativeCurrencies: currencies.filter((currency) => !currency.is_default)
    }
}

export const getTenantsLanguages = (state) => {
    const languages = [];
    state.Tenants.tenants.forEach((tenant) => {
        if (tenant.locales === undefined) {
            return
        }

        Object.entries(tenant.locales).forEach(entry => {
            const [locale] = entry
            languages.push(locale)
        })
    })

    // Return unique locales
    return [...new Set(languages)]
}
