import {tenantsClient} from '../../utils/Http'
import TenantActions from '../../store/tenants/actions';
import UIActions from '../../store/UI/actions';
import i18n from '../../i18n';

/**
 * Fetch Tenants and their public data. Filter only those Tenants which has allowed Portal application and store them.
 *
 * @returns {function(*): *}
 */
export const fetchTenantsWithEnabledPortal = () => {
    return dispatch => {
        return tenantsClient.get(`/tenants`)
            .then(({data}) => {
                const tenants = data.data.filter((tenant) => {
                    let found = false;
                    if (tenant.modules !== undefined) {
                        Object.keys(tenant.modules).forEach((key) => {
                            if (key === 'Portal') {
                                found = true
                            }
                        })
                    }

                    return found
                })

                if (tenants.length === 0) {
                    dispatch(UIActions.showPage401(true, i18n.t('tenants:noTenantsAvailableForThisModule')))
                } else {
                    dispatch(TenantActions.tenantsFetched(tenants))
                }
            })
            .catch((error) => {
                dispatch(UIActions.showPage500(true, i18n.t('tenants:failedToLoadTenants')))
            })
    }
}
