import {combineReducers} from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr';

import UI from './UI/reducer';
import Auth from './authentication/reducer';
import UserVerifications from './userVerifications/reducer';
import Customer from './customer/reducer';
import Enums from './enums/reducer';
import Tenants from './tenants/reducer';
import IndividualSalesService from './individualSalesService/reducer';

export default combineReducers({
    UI,
    Auth,
    UserVerifications,
    Customer,
    Enums,
    Tenants,
    IndividualSalesService,
    toastr: toastrReducer,
});
