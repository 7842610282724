import axios from 'axios'

/**
 * Process common responses like server error, not allowed etc.
 *
 * @param response
 */
const processErrorResponse = response => {
    switch (response.status) {
        case 500:
            // toastr.error('Nastala chyba na straně serveru. Váš požadavek se nepodařilo zpracovat.');
            break;
        default:
            break;
    }
};

// const {keycloak, initialized} = useKeycloak();
// const token = keycloak?.token ?? '';

axios.defaults.headers.common.Accept = 'application/json';
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-Tenant-Code'] = 'CZ';
axios.defaults.headers.common['X-User-Lang'] = 'cs';
axios.interceptors.response.use(
    response => response,
    (error) => {
        processErrorResponse(error);
        return Promise.reject(error);
    });

const profileManagerClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_DOMAIN}/${process.env.REACT_APP_API_PATH}`,
})

const tenantsClient = axios.create({
    baseURL: `${process.env.REACT_APP_TENANTS_API_DOMAIN}/${process.env.REACT_APP_TENANTS_API_PATH}`,
})

const issClient = axios.create({
    baseURL: `${process.env.REACT_APP_ISS_API_DOMAIN}/${process.env.REACT_APP_ISS_API_PATH}`,
})

export {profileManagerClient, tenantsClient, issClient};
