import actionTypes from "./actionTypes";

const initialState = {
    request: {
        data: {},
        isLoading: true,
        hasError: false,
        errors: [],
    },

    cardholder: {
        data: [],
        isLoading: true,
        hasError: false,
        errors: [],
    },

    payment: {
        data: [],
        isLoading: true,
        hasError: false,
        errors: [],
    },

    emails: {
        data: [],
        isLoading: true,
        hasError: false,
        errors: [],
    },

    paymentMethods: {},
};

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case actionTypes.SET_REQUEST_LOADING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: payload
                },
            };
        case actionTypes.SET_REQUEST:
            return {
                ...state,
                request: {
                    ...state.request,
                    data: payload,
                    hasError: false,
                },
            };
        case actionTypes.SET_REQUEST_ERRORS:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: false,
                    hasError: payload.hasError,
                    errors: payload.errors
                },
            };
        case actionTypes.SET_CARDHOLDER_LOADING:
            return {
                ...state,
                cardholder: {
                    ...state.cardholder,
                    isLoading: payload
                },
            };
        case actionTypes.SET_CARDHOLDER:
            return {
                ...state,
                cardholder: {
                    ...state.cardholder,
                    data: payload,
                    hasError: false,
                },
            };
        case actionTypes.SET_CARDHOLDER_ERRORS:
            return {
                ...state,
                cardholder: {
                    ...state.cardholder,
                    isLoading: false,
                    hasError: payload.hasError,
                    errors: payload.errors
                },
            };
        case actionTypes.SET_PAYMENT_LOADING:
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isLoading: payload
                },
            };
        case actionTypes.SET_PAYMENT:
            return {
                ...state,
                payment: {
                    ...state.payment,
                    data: payload,
                    hasError: false,
                },
            };
        case actionTypes.SET_PAYMENT_ERRORS:
            return {
                ...state,
                payment: {
                    ...state.payment,
                    isLoading: false,
                    hasError: payload.hasError,
                    errors: payload.errors
                },
            };
        case actionTypes.SET_EMAILS_LOADING:
            return {
                ...state,
                emails: {
                    ...state.emails,
                    isLoading: payload
                },
            };
        case actionTypes.SET_EMAILS:
            return {
                ...state,
                emails: {
                    ...state.emails,
                    data: payload,
                    hasError: false,
                },
            };
        case actionTypes.SET_EMAILS_ERRORS:
            return {
                ...state,
                emails: {
                    ...state.emails,
                    isLoading: false,
                    hasError: payload.hasError,
                    errors: payload.errors
                },
            };
        case actionTypes.SET_PAYMENT_METHODS:
            const { tenantId, paymentMethods } = payload

            return {
                ...state,
                paymentMethods: {
                    ...state.paymentMethods,
                    [tenantId]: paymentMethods,
                },
            };
        case actionTypes.CLEANUP:
            return {
                ...initialState
            };

        default:
            return state;
    }
};

export default reducer;
