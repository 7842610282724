import uiTypes from './actionTypes';

const actions = {
    tenantsLoading,
    tenantsFetched,
    tenantSelected,
}

export default actions;

function tenantsLoading(isLoading) {
    return {type: uiTypes.TENANTS_LOADING, isLoading};
}

function tenantsFetched(tenants) {
    return {type: uiTypes.TENANTS_FETCHED, tenants};
}

function tenantSelected(tenantCode) {
    return {type: uiTypes.TENANT_SELECTED, tenantCode};
}
