import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {icons} from './assets/icons'
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {ReactKeycloakProvider} from '@react-keycloak/web';
import keycloak from './keycloak';
import './i18n/index';

import {Provider} from 'react-redux'
import configureStore from './store/configureStore'
import {onKeycloakTokensReceived} from "./services/authService";
import {Router} from "react-router-dom";
import {createBrowserHistory} from 'history';
import UIActions from './store/UI/actions';
import FullScreenLoader from "./components/FullScreenLoader";
import 'react-dates/initialize';

const history = createBrowserHistory();

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        tracingOrigins: [
            "localhost",
            "pm.test.gts.bootiq-preview.eu",
            "portal.test.aliveplatform.com",
            "portal.uat.aliveplatform.com",
            /^\//,
        ]
    })],
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
});

React.icons = icons;
const store = configureStore();
const loading = <FullScreenLoader/>;

const render = Component => {
    const eventLogger = (event, error) => {
        if (process.env.REACT_APP_IS_DEBUG) {
            console.log('onKeycloakEvent', event, error)
        }

        switch (event) {
            case 'onAuthError':
            case 'onInitError':
            case 'onAuthRefreshError':
                store.dispatch(UIActions.showPage500(true, 'Error while communicating with IAM'));
                break;
            default:
                break;
        }
    }

    return ReactDOM.render(
        <Sentry.ErrorBoundary fallback={"Error occured, we are sorry"}>
            <Provider store={store}>
                <ReactKeycloakProvider
                    authClient={keycloak}
                    onEvent={eventLogger}
                    onTokens={onKeycloakTokensReceived}
                >
                    <Router history={history}>
                        <React.Suspense fallback={loading}>
                            <Component/>
                        </React.Suspense>
                    </Router>
                </ReactKeycloakProvider>
            </Provider>
        </Sentry.ErrorBoundary>,
        document.getElementById('root')
    );
};

render(App);

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
