import React from 'react'
import {
    CCol,
    CContainer,
    CRow
} from '@coreui/react'
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import UIActions from '../../../store/UI/actions';
import i18n from '../../../i18n';

const Page500 = () => {
    const dispatch = useDispatch();
    const message = useSelector(state => state.UI.page401.message);

    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="6">
                        <span className="clearfix">
                            <h1 className="float-left display-3 mr-4">401</h1>
                            <h4 className="pt-3">{i18n.t('common:errors.error401.title')}</h4>
                            <p className="text-muted float-left">
                                {message ? message : i18n.t('common:errors.error401.message')}
                            </p>
                        </span>

                        <div className="d-flex justify-content-center mt-2">
                            <Link
                                to="/"
                                className="btn btn-info"
                                style={{width: '75%'}}
                                onClick={() => dispatch(UIActions.showPage401(false))}
                            >
                                {i18n.t('common:errors.getBackButton')}
                            </Link>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

Page500.propTypes = {
    children: PropTypes.string,
}

export default Page500
