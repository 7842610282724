export const DEFAULT_LIMIT = 15;

export const ORDER_DIRECTION_ASC = 'asc';
export const ORDER_DIRECTION_DESC = 'desc';

export const FILTER_TYPE_IS_NULL = 'is null';
export const FILTER_TYPE_IS_NOT_NULL = 'is not null';
export const FILTER_TYPE_EQUAL = 'eq';
export const FILTER_TYPE_LIKE = 'like';
export const FILTER_TYPE_IN = 'in';

export const CONNECTOR_OR = 'or';
export const CONNECTOR_AND = 'and';

export default class PaginationFilterBuilder {
    constructor(url = null) {
        this.filters = [];
        this.limit = null;
        this.orderBy = null;
        this.orderDirection = null;
        this.page = null;
        this.url = url;
    }

    /**
     * Add new filter with given parameters
     *
     * @param {String} name Name of relation or column
     * @param {Any} value Value by which you want to filter
     * @param {?String} type Method of filtering, ex. LIKE, eq, gt, lte..
     * @param {?String} connector
     */
    addFilter = (name, value, type = null, connector = null) => {
        // Skip if filter of given name already exists.
        if (this.filters.find(filter => filter.name === name)) {
            return;
        }

        // Add given attributes into filters
        this.filters.push({
            name,
            value,
            type,
            connector,
        });
    }

    /**
     * Set limit of how many records you want to return on page.
     *
     * @param {Number} limit
     */
    setLimit = limit => this.limit = limit;

    /**
     * Set page which you want to show.
     *
     * @param {Number} page
     */
    setPage = page => this.page = page;

    /**
     * Set ordering - by which column to order and what direction.
     *
     * @param {String} orderBy
     * @param {String} orderDirection
     */
    setOrdering = (orderBy, orderDirection = ORDER_DIRECTION_ASC) => {
        this.orderBy = orderBy;
        this.orderDirection = orderDirection;
    }

    /**
     * Return final query parameters to filter data from backend.
     *
     * @returns {string}
     */
    getQueryParams = () => {
        // Query parts should contains filter, limit, ordering
        const queryParts = [];
        const queries = this.filters.reverse();

        // Combine filter parameters
        queryParts.push(queries.map(({name, value, type, connector}) => {
            let query = '';

            if (type !== FILTER_TYPE_IS_NULL && type !== FILTER_TYPE_IS_NOT_NULL) {
                query = `filter[${name}]${type || connector ? '[x]' : ''}=${encodeURI(value)}`;
            }

            if (type !== null) {
                if (type !== FILTER_TYPE_IS_NULL && type !== FILTER_TYPE_IS_NOT_NULL) {
                    query += '&';
                }

                query += `filter[${name}][type]=${encodeURI(type)}`;
            }

            if (connector !== null) {
                query += `&filter[${name}][connector]=${encodeURI(connector)}`;
            }

            return query;
        }).join('&'));

        // Add page limit
        if (this.limit !== null) {
            queryParts.push(`limit=${this.limit}`);
        }

        // Add page limit
        if (this.page !== null) {
            queryParts.push(`page=${this.page}`);
        }

        // Add ordering
        if (this.orderBy !== null) {
            queryParts.push(`sortby=${this.orderBy}`);
            queryParts.push(`sortdir=${this.orderDirection}`);
        }

        return queryParts.filter(part => part !== '').join('&');
    }

    /**
     * Return full URL with query parameters.
     *
     * @returns {string}
     */
    getUrl = () => `${this.url}?${this.getQueryParams()}`;
};
