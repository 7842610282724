const actionTypes = {
    SET_VERIFICATIONS_LOADING: 'userVerifications/setVerificationsLoading',
    VERIFICATIONS_CLEANUP: 'userVerifications/verificationsCleanup',
    SET_USER_VERIFICATIONS: 'userVerifications/setUserVerifications',
    ADD_USER_VERIFICATIONS: 'userVerifications/addUserVerifications',
    ADD_APPROVED: 'userVerifications/addApproved',
    ADD_DENIED: 'userVerifications/addDenied',
    RESET_RESOLUTION: 'userVerifications/resetResolution',
    RESET_ALL_RESOLUTIONS: 'userVerifications/resetAllResolutions',
    SUBMIT_RESOLUTIONS: 'userVerifications/submitResolutions',
    SET_RESOLUTIONS_SUBMITTING: 'userVerifications/setResolutionsSubmitting',
    SET_FILTER_VALUE: 'userVerifications/setFilterValue',
    SET_ID_FILTER_VALUE: 'userVerifications/setIdFilterValue',
    RESET_FILTER_VALUES: 'userVerifications/resetFilterValues',
    INCREASE_PAGE_NUMBER: 'userVerifications/increatePageNumber',
    SET_PAGE_NUMBER: 'userVerifications/setPageNumber',
    ADD_PICTURE_PROFILE_IMAGE: 'userVerifications/addPictureProfileImage',
    ADD_PICTURE_CONFIRMATION_DOCUMENT: 'userVerifications/addPictureConfirmationDocument',
};

export default actionTypes;
