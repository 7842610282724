import uiTypes from "./actionTypes";

const actions = {
    showSidebar,
    showAside,
    darkMode,
    showPage500,
    showPage401,
    showPage404,
    addScrollNavigation,
    cleanupScrollNavigation,
}

export default actions;

function showSidebar(show) {
    return {type: uiTypes.SHOW_SIDEBAR, sidebarShow: show};
}

function showAside(show) {
    return {type: uiTypes.SHOW_ASIDE, asideShow: show};
}

function darkMode(enabled) {
    return {type: uiTypes.DARKMODE, darkMode: enabled};
}

function showPage500(show, message = null) {
    const payload = {page500: {show}};
    if (message !== null) {
        payload['page500']['message'] = message;
    }

    return {type: uiTypes.SHOW_PAGE_500, ...payload};
}

function showPage401(show, message = null) {
    const payload = {page401: {show}};
    if (message !== null) {
        payload['page401']['message'] = message;
    }

    return {type: uiTypes.SHOW_PAGE_404, ...payload};
}

function showPage404(show, message = null) {
    const payload = {page404: {show}};
    if (message !== null) {
        payload['page404']['message'] = message;
    }

    return {type: uiTypes.SHOW_PAGE_404, ...payload};
}

function addScrollNavigation(id, content, ref) {
    return {type: uiTypes.ADD_SCROLL_NAVIGATION, id, content, ref};
}

function cleanupScrollNavigation() {
    return {type: uiTypes.CLEANUP_SCROLL_NAVIGATION};
}
