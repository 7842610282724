import Loader from "../Spinkit";
import React from "react";
import PropTypes from 'prop-types';

const FullScreenLoader = ({children}) => {
    return (
        <div
            className="d-flex justify-content-center align-items-center flex-column position-absolute"
            style={{position: 'absolute', width: '100%', height: '100%'}}>
            <Loader>
                {children}
            </Loader>
        </div>
    )
};

FullScreenLoader.propTypes = {
    children: PropTypes.string,
};

export default FullScreenLoader;
