import React from 'react';
import PropTypes from 'prop-types';
import 'spinkit/spinkit.min.css';
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const Loader = ({children, classNames, style}) => {
    return (
        <Wrapper className={classNames}>
            <div className={`sk-circle`} style={{...style}}>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
                <div className="sk-circle-dot"/>
            </div>

            {children ? <span className="mt-4">{children}</span> : '' }
        </Wrapper>
    );
};

Loader.propTypes = {
    children: PropTypes.string,
    classNames: PropTypes.string,
    style: PropTypes.object,
};
Loader.defaultProps = {
    style: {},
};

export default Loader;
