const actionTypes = {
    SHOW_SIDEBAR: 'UI/showSidebar',
    SHOW_ASIDE: 'UI/showAside',
    DARKMODE: 'UI/darkMode',
    SHOW_PAGE_500: 'UI/showPage500',
    SHOW_PAGE_401: 'UI/showPage401',
    SHOW_PAGE_404: 'UI/showPage404',
    ADD_SCROLL_NAVIGATION: 'UI/showScrollNavigation',
    CLEANUP_SCROLL_NAVIGATION: 'UI/cleanUpScrollNavigation',
};

export default actionTypes;
