/**
 * Main store function
 */
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import {composeWithDevTools} from "redux-devtools-extension";
import * as Sentry from '@sentry/react';

const configureStore = (initialState = {}) => {
    // Middleware and store enhancers
    const enhancers = [
        applyMiddleware(thunk),
        Sentry.createReduxEnhancer(),
    ];

    const store = createStore(rootReducer, initialState, composeWithDevTools(...enhancers))

    // For hot reloading reducers
    if (process.env.NODE_ENV !== 'production' && module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
    }

    return store;
};

export default configureStore;
