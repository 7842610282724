import { profileManagerClient, tenantsClient, issClient } from '../utils/Http';

/**
 * Bind tokens into local storage and configure axios with authorization header.
 *
 * @param {Object} tokens
 * @return void
 */
export const onKeycloakTokensReceived = tokens => {
    // Store tokens in localStorage
    localStorage.setItem('access_token', tokens.token);
    localStorage.setItem('refresh_token', tokens.refreshToken);

    // Bind token as authentication method in axios
    profileManagerClient.defaults.headers.common['Authorization'] = `Bearer ${tokens.token}`;
    tenantsClient.defaults.headers.common['Authorization'] = `Bearer ${tokens.token}`;
    issClient.defaults.headers.common['Authorization'] = `Bearer ${tokens.token}`;

    if (process.env.REACT_APP_IS_DEBUG) {
        console.log('Keycloak - tokens received');
    }
};
