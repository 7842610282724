import {sygnet} from './sygnet'
import {logo} from './logo'
import {logoNegative} from './logo-negative'

import {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilBell,
    cilEnvelopeOpen,
    cilTask,
    cilCommentSquare,
    cilUser,
    cilSettings,
    cilCreditCard,
    cilFile,
    cilAccountLogout,
    cilCheckAlt,
    cilCheckCircle,
    cilArrowLeft,
    cilX,
    cilXCircle,
    cilSearch,
    cilExternalLink,
    cilAddressBook,
    cilPen,
    cilCart,
} from '@coreui/icons'

export const icons = Object.assign({}, {
    sygnet,
    logo,
    logoNegative
}, {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilBell,
    cilEnvelopeOpen,
    cilTask,
    cilCommentSquare,
    cilUser,
    cilSettings,
    cilCreditCard,
    cilFile,
    cilAccountLogout,
    cilCheckAlt,
    cilCheckCircle,
    cilArrowLeft,
    cilX,
    cilXCircle,
    cilSearch,
    cilExternalLink,
    cilAddressBook,
    cilPen,
    cilCart,
})
