import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import namespaces from "./namespaces";
import languages from './languages';
import moment from "moment";

import {profileManagerClient, tenantsClient, issClient} from '../utils/Http';

export const DEFAULT_FALLBACK_LANGUAGE = 'en'

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // pass plural interval to translate like like. "One Item", "A few items", "A lot of items"
    // learn more: https://github.com/i18next/i18next-intervalPlural-postProcessor
    .use(intervalPlural)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: code => {
            const fallbacks = [];

            // if is current language an alternative, fallback its main language
            if (code.includes('-')) {
                fallbacks.push(code.split('-')[0])
            }

            // General fallbacks for all languages
            fallbacks.push(DEFAULT_FALLBACK_LANGUAGE);

            return fallbacks;
        },
        debug: process.env.REACT_APP_IS_DEBUG === 'true',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        defaultNS: 'common',
        ns: namespaces,
        load: 'languageOnly',
    });

languages.forEach(language => {
    namespaces.forEach(namespace => {
        import(`../locales/${namespace}/${language}.json`).then(module => i18n.addResourceBundle(
            language,
            namespace,
            module.default
        ));
    });
});

const enMoment = require('moment/locale/en-gb');
const csMoment = require('moment/locale/cs');
const roMoment = require('moment/locale/ro');
const arMoment = require('moment/locale/ar');

// Custom event listener on language change.
// Useful to change currency libraries, moment date libraries etc.
i18n.on('languageChanged', languageCode => {
    profileManagerClient.defaults.headers.common['X-User-Lang'] = languageCode;
    tenantsClient.defaults.headers.common['X-User-Lang'] = languageCode;
    issClient.defaults.headers.common['X-User-Lang'] = languageCode;

    // Set moment global locale
    switch (languageCode) {
        case 'en':
        case 'en-GB':
        case 'en-US':
            moment.updateLocale('en', enMoment)
            break
        case 'cs-CZ':
        case 'cs':
            moment.updateLocale('cs', csMoment)
            break
        case 'ro':
            moment.updateLocale('ro', roMoment)
            break
        case 'ar':
            moment.updateLocale('ar', arMoment)
            break
        default:
            moment.updateLocale('en', enMoment)
            break
    }
});


export default i18n;
