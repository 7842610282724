export const STATE_APPROVED = 'APPROVED';
export const STATE_DENIED = 'DENIED';
export const STATE_WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL';

const states = {
    APPROVED: 1,
    DENIED: 2,
    WAITING_FOR_APPROVAL: 3,
};

export default states;
