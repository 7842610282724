import actionTypes from "./actionTypes";

const initialState = {
    // Customer
    isLoading: true,
    customer: {
        cards: [],
        insurances: [],
    },
    validationErrors: {},

    // ProfilePicture
    isProfilePictureLoading: true,
    profilePicture: null,

    // Cards
    isCardsLoading: true,
    cards: [],
    activeCard: {},
    isActiveCardLoading: false,

    // Insurances
    isInsurancesLoading: true,
    insurances: [],
    activeInsurance: {},
    isActiveInsuranceLoading: false,

    // Agreements
    isAgreementsLoading: true,
    agreements: [],
};

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        // Customer
        case actionTypes.CUSTOMERS_CLEANUP:
            return {
                ...initialState,
            };
        case actionTypes.SET_CUSTOMER:
            return {
                ...state,
                customer: {...payload},
                validationErrors: {},
            };
        case actionTypes.SET_CUSTOMER_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        case actionTypes.ADD_VALIDATION_ERROR:
            const {name, value} = payload;
            let validationErrors = {...state.validationErrors};
            if (!validationErrors.hasOwnProperty(name)) {
                validationErrors[name] = [];
            }

            if (!validationErrors[name].includes(value)) {
                validationErrors[name].push(value);
            }

            return {
                ...state,
                validationErrors,
            };
        case actionTypes.REMOVE_VALIDATION_ERRORS:
            validationErrors = {...state.validationErrors};
            if (validationErrors.hasOwnProperty(payload)) {
                delete validationErrors[payload];
            }

            return {
                ...state,
                validationErrors,
            };

        // Profile Picture
        case actionTypes.SET_PROFILE_PICTURE_LOADING:
            return {
                ...state,
                isProfilePictureLoading: payload,
            };
        case actionTypes.SET_PROFILE_PICTURE:
            return {
                ...state,
                profilePicture: payload
            };

        // Cards
        case actionTypes.SET_CARDS_LOADING:
            return {
                ...state,
                isCardsLoading: payload,
            };
        case actionTypes.SET_CARDS:
            return {
                ...state,
                cards: [...payload]
            };
        case actionTypes.SET_ACTIVE_CARD:
            return {
                ...state,
                activeCard: {...payload}
            };
        case actionTypes.SET_ACTIVE_CARD_LOADING:
            return {
                ...state,
                isActiveCardLoading: payload,
            };

        // Insurances
        case actionTypes.SET_INSURANCES_LOADING:
            return {
                ...state,
                isInsurancesLoading: payload,
            };
        case actionTypes.SET_INSURANCES:
            return {
                ...state,
                insurances: [...payload]
            };
        case actionTypes.SET_ACTIVE_INSURANCE:
            return {
                ...state,
                activeInsurance: {...payload}
            };
        case actionTypes.SET_ACTIVE_INSURANCE_LOADING:
            return {
                ...state,
                isActiveInsuranceLoading: payload,
            };

        // Agreements
        case actionTypes.SET_AGREEMENTS_LOADING:
            return {
                ...state,
                isAgreementsLoading: payload,
            };
        case actionTypes.SET_AGREEMENTS:
            return {
                ...state,
                agreements: [...payload]
            };

        default:
            return state;
    }
};

export default reducer;
