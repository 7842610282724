import authTypes from "./actionTypes";

const initialState = {
    isAuthenticated: false,
};

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case authTypes.CHECK_AUTH:
            return {
                ...state,
                isAuthenticated: !!localStorage.getItem('access_token')
            };
        case authTypes.LOGOUT:
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');

            return {
                ...state,
                isAuthenticated: false
            };
        default:
            return state;
    }
};

export default reducer;
