import actionTypes from "./actionTypes";

const initialState = {
    languages: {
        isLoading: true,
        data: [],
    },
};

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case actionTypes.ENUMS_CLEANUP:
            return {
                ...initialState,
            };
        case actionTypes.SET_LANGUAGES_LOADING:
            return {
                ...state,
                languages: {
                    ...state.languages,
                    isLoading: payload,
                },
            };
        case actionTypes.SET_LANGUAGES:
            return {
                ...state,
                languages: {
                    ...state.languages,
                    data: [...payload],
                },
            };

        default:
            return state;
    }
};

export default reducer;
