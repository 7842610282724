const actionTypes = {
    SET_REQUEST_LOADING: 'individualSalesService/setRequestLoading',
    SET_REQUEST: 'individualSalesService/setRequest',
    SET_REQUEST_ERRORS: 'individualSalesService/setRequestErrors',

    SET_CARDHOLDER_LOADING: 'individualSalesService/setCardholderLoading',
    SET_CARDHOLDER: 'individualSalesService/setCardholder',
    SET_CARDHOLDER_ERRORS: 'individualSalesService/setCardholderErrors',

    SET_PAYMENT_LOADING: 'individualSalesService/setPaymentLoading',
    SET_PAYMENT: 'individualSalesService/setPayment',
    SET_PAYMENT_ERRORS: 'individualSalesService/setPaymentErrors',

    SET_EMAILS_LOADING: 'individualSalesService/setEmailsLoading',
    SET_EMAILS: 'individualSalesService/setEmails',
    SET_EMAILS_ERRORS: 'individualSalesService/setEmailsErrors',

    SET_PAYMENT_METHODS: 'individualSalesService/setPaymentMethods',

    CLEANUP: 'individualSalesService/cleanup'
};

export default actionTypes;
